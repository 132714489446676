import React from 'react';
import Layout from '../components/Layout';
import { StaticImage } from 'gatsby-plugin-image'
import { SEO } from "../components/Seo"
import styled from 'styled-components';
import ZawodyInfo from '../components/ZawodyInfo';

const Title = styled.h2`
  text-align: center;
  font-size: 1.8em;
  font-weight: 100;
  padding: 20px;
  span{
    font-weight: 500;
    color: #B68A65;
  }

  ${({theme})=> theme.media.tablet}{
    font-size: 2em;
    max-width: 80vw;
    margin: auto;
  }
`

const Story = styled.h3`
  text-align: center;
  font-size: 1.2em;
  font-weight: 100;
  padding: 20px;
  width: 80%;
  margin: auto;
  span{
    font-weight: 500;
    color: #B68A65;
  }
`

const Win = styled.section`
  padding: 20px;


  h4{
    text-decoration: underline;
    color: #B68A65;
    padding-top: 10px;
  }
  h4,p {
    text-align: center;
  }

  ul{
    font-weight: 300;
    padding-top: 5px;
  }

  h5{
    font-size: 1.2em;
    font-weight: 100;
    padding: 20px;
    text-align: center;
  }

  a{
    color: black;
    font-weight: 500;
    /* text-decoration: underline; */
  }

  .img{
    border: 10px solid whitesmoke;
    box-shadow: gray;
    margin: 10px 0px;
  }

  ${({theme})=> theme.media.tablet}{
    max-width: 60vw;
    margin: auto;
  }
  .star{
    text-align: left;
    font-size: 0.8em;
    ${({theme})=> theme.media.tablet}{
      font-size: 0.9em;
    }
  }
`

const Zawody = () => {
    return (
        <Layout>
            <ZawodyInfo/>
            <Title>Nagrody w zawodach gravelowych <br></br><span>Ultra Race Dolina Bugu 2024</span></Title>
            <Story>Wiemy, że Gravelowe zawody to przede wszystkim spotkania z ludźmi, dużo pozytywnych emocji i walka samym z sobą. Ale każda przygoda rowerowa smakuje lepiej jak można rywalizować z innymi na trasie. Dlatego też przygotowaliśmy dla Was nagrody. <span>Pula nagród będzie się powiększać.</span></Story>

             <Win>
        <Story>Nagrody przewidziane dla dystansu GIGANTE i CLASSICO </Story>
        <h4>KATEGORIA INDYWIDUALNA</h4>
        <p>I-III miejsce</p>
          <ul>Open Kobiety </ul>
            <li>pamiątkowe statuetki</li>
            <li>nagrody rzeczowe</li>
          <ul>Open Mężczyźni</ul>
            <li>pamiątkowe statuetki</li>
            <li>nagrody rzeczowe</li>
<br></br>
          
        <h4>KATEGORIA DRUŻYNOWA*</h4>
        <p>W rywalizacji drużynowej wymagana jest liczba 3-5 uczestników, pomiar czasu liczony jest najlepszym 3 zawodniczkom/zawodnikom.</p>
        <ul>Najlepsza Drużyna Open</ul>
          <li>pobyt (3 noclegi) w <a href='https://morehomecycling.pl' target={"_blank"} rel="noreferrer">Dom dla kolarzy - MoreHomeCycling.pl</a> - dla maks. 7 osób</li>
        <a href='https://morehomecycling.pl' target={"_blank"} rel="noreferrer">
          <StaticImage
              src="../images/moreHome.jpeg"
              alt="more home dom dla kolarzy"
              placeholder="blurred"
              layout='constrained'
            //   width={600}
              height={600}     
              transformOptions={{ fit: "cover", cropFocus: "attention" }}
              className='img'
            ></StaticImage></a>
        <p className='star'>*Musi wystartować minimum 3 drużyny na danym dystansie.</p>
        <br></br>
        <h4>LOSOWANIE</h4>
        <p>Dodatkowo nagrody rzeczowe za udział w zawodach, rozlosowane zostaną wśród wszystkich uczestników, którzy przejadą chociaż jeden kilometr zaplanowanej trasy.</p>
        </Win>
            
        </Layout>
    );
}

export default Zawody;

export const Head = () => (<SEO title="Nagrody zawody gravelowe | Ultramaraton Rowerowy" description="Zapisz się na Ultramaraton Rowerowy Dolina Bugu."/>
)