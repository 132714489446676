import React from 'react';
import styled from 'styled-components';
import RaceBook from "../assets/RaceBookURDB.pdf"
import ListaS from "../assets/URDB24_lista.pdf"


const Title = styled.h1`
  text-align: center;
  font-size: 1.8em;
  font-weight: 100;
  padding: 20px;
  /* color: #B68A65; */

  

  ${({theme})=> theme.media.tablet}{
    font-size: 2em;
    max-width: 80vw;
    margin: auto;
  }
`

const InfoStyle = styled.section`
    div{
        padding-bottom: 15px;
        h3{
            color: #B68A65;
            /* text-decoration: underline; */
            font-weight: normal;
        }
    }

    ${({theme})=> theme.media.tablet}{
        max-width: 60vw;
        margin: auto;

  }
`
const Rb = styled.section`
    display: block;
    text-align: center;
    margin: 40px;
    a{
        text-decoration: none;
        color: #B68A65;
        font-size: 2em;
        :hover{
          color: #B68A65;
          text-decoration: underline;
        }
      }
`

const ZawodyInfo = () => {
    return (
        <InfoStyle>
            <Rb>
                <a href={RaceBook} title="Race Book Ultra Race Dolina Bugu">RaceBook URDB 2024</a>
            </Rb>
            <Rb>
                <a href={ListaS} title="Lista startowa">Lista startowa URDB 2024</a>
            </Rb>
      
            {/* <Title>Informacje praktyczne</Title>
            <div>
                <h3>Start</h3>
                <p>GIGANTE 550km - 30.08.2024 - Chełm</p>
                <p>CLASSICO 290km - 31.08.2023 - Biała Podlaska</p>
                <p>Grupy będą wypuszczane po maks. 15 osób co 5 minut w od ok. 7:00.</p>
                <br/>
                <h3>Wspólna meta</h3>
                <p>Wyszków</p>
            </div>
            
            <br/>
            <div>
                <p>GPX, mapa dostępnych parkingów oraz RaceBook (wybrane sklepy na trasie, utrudnienia w ruchu, wrażliwe miejsca itp.)zostaną przesłane na podany przy rejestracji e-mail 7 dni przed startem zawodów.</p>
                <br></br>
                <p>Zapisy zawodników na poszczególne godziny startu zostaną udostępnione na 10 dni przed startem, decyduje kolejność zapisu na listę.</p>
            </div>    */}
        </InfoStyle>
    );
}

export default ZawodyInfo;

